import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Content from "../components/content"
import SEO from "../components/seo"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Footer from "../components/footer"
import NewsPreview from '../components/news-preview'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from '../components/customForm'

import showdown from 'showdown'

const HomePage = ({data, pageContext}) => {
    const slugTable = {
    "1": "hwcid-1",
    "2": "hwcid-2",
    }
    const page = data.graphAPI.page
    return (
        <Layout>
            <SEO title={page.title} />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="content-align">
                        {/* <div className="message is-danger" style={{marginBottom: '0rem', marginTop: '2rem'}}>
                            <div className="message-header">
                                Important Water Update for Hays County WCID 1 & 2
                            </div>
                            <div className="message-body">
                                <p> Hays County WCID No. 1 and Hays County WCID No. 2 Residents – due to critically low tank levels, the WTCPUA has implemented Stage 3 watering restrictions to curtail water use. Residents should reduce water use as much as possible. Outdoor watering is still permitted 2 days per week for each residence, but <strong>watering must occur between the hours of midnight and 6 a.m on designated watering days only.</strong> You may refer to the District website (<Link to="/">www.hayswcid.org</Link>) to determine the designated watering days for your residence. Please note that the watering schedule for Belterra differs from the WTCPUA watering schedule.  In addition, please be advised that filling of swimming pools is prohibited, and no new landscapes may be installed.</p>
 
                                To avoid potential confusion, we are taking this opportunity to remind residents that WCID No. 1 and WCID No. 2 irrigate many common areas with reclaimed water from our wastewater plant, as opposed to drinking water from the WTCPUA. Irrigation with reclaimed water is our primary method of reuse, and outdoor watering restrictions do not apply to these common areas

                                <p>Belterra is NOT included in the Boil Water Notice that was issued by the WTCPUA on the evening of Monday, June 13, 2022.</p>

                                The Water Main Break on Hwy 290 Heritage Oaks West to Polo Club does not have any effect on the Belterra Community.
                            </div>
                    </div> */}
                    <div className="title-section">
                        {/* <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {pageContext.district}</small> */}

                        </div>
                        {/* <h1 className="title is-size-1">{page.title}</h1> */}
                    </div>
                    <Content content={page.content} district={pageContext.district} />
                    <div className="title section has-text-centered">
                        <h2 className="title is-size-1">Latest News </h2>
                        {pageContext.district === '1' ? (
                            <>
                            <MailchimpSubscribe
                            url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=40b1921fbd&amp;f_id=00328ce4f0"
                            render={({ subscribe, status, message }) => (
                                <CustomForm
                                status={status}
                                message={message}
                                onValidated={(formData) => {subscribe({...formData, "tags": "1871146,1871142"})}}
                                />
                            )}
                            />
                            </>
                        ) : (
                            <>
                            <MailchimpSubscribe
                            url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=40b1921fbd&amp;f_id=00328ce4f0"
                            render={({ subscribe, status, message }) => (
                                <CustomForm
                                status={status}
                                message={message}
                                onValidated={(formData) => {subscribe({...formData, "tags": "1871146,1871150"})}}
                                />
                            )}
                            />
                            </>
                        )}
                    </div>
                    <NewsPreview district={pageContext.district} posts={data.graphAPI.blogPosts} />
                    <div style={{ padding: '1rem'}} className="has-text-centered">
                        <p>You've reached the end. <Link to={`/${slugTable[pageContext.district]}/archive`}>View News Archive</Link></p>
                    </div>


                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default HomePage

export const homePageQuery = graphql`
    query homePageById($id: ID! $district: ID!) {
        graphAPI {
            page(id: $id) {
                id
                title
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        Body
                        minHeight
                        id
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        id
                        Placement
                        Width
                        Image {
                            url
                            updated_at
                            documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                }
            }
            blogPosts(where: {districts: {id: $district}}, limit: 6, sort: "publishedAt:desc") {
                id
                title
                description
                publishedAt
                heroImage {
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


