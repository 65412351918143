import React from 'react'

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div className="">
        <div className="">
          <div className="mb-4">
              <span className="is-size-5">Signup for eNews: </span>
              <input className="input is-inline" ref={node => (email = node)} type="email" name="email" placeholder="Enter your email"  />
              <button className="button is-primary" onClick={submit}>Submit</button>
          </div>
        </div>
        {status === "sending" && <div className="notification is-primary is-inline is-size-5">Sending...</div>}
        {status === "error" && (
          <div className="notification is-danger is-inline is-size-5"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div className="notification is-success is-inline is-size-5"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  };

export default CustomForm;